import React, { useContext, useRef, useState } from 'react';
import {
  RDSButton,
  RDSIconTextButton,
  UploadIcon,
  OpenModelIcon,
  ModelIcon,
  LogoutIcon,
  InfoOutlinedIcon,
  PeopleIcon,
  QuestionAnswerIcon,
  AutoGraphIcon,
  ModalMaker,
  RDSGNBListButton,
  CreditCardOutlinedIcon,
} from '@reconlabs/reconlabs-fe-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import amplitude from '../utils/amplitude';
import amplitudeEvents from '../constants/amplitudeEvents';
import { JWT_ACCESS_TOKEN_LOCALSTORAGE_KEY, JWT_REFRESH_TOKEN_LOCALSTORAGE_KEY } from '../utils/localstorage';
import { UserContext } from './context/UserContextProvider';
import { revokeToken } from '../utils/login';
import { checkOverViewCount, checkRemainingModelCount } from '../utils/limit';
import Logo from '../images/gnblogo.png';
import RDSUpload from './pages/fileupload/RDSUpload';
import { validationFile } from '../utils/utils';
import { DashboardContext } from './context/DashboardContextProvider';

const DashboardNavigator = () => {
  /*----------------------------------------
                     Data
   ----------------------------------------*/
  const location = useLocation();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);

  const { setIsOverUploadModalOpen, setSpinner, setRemainingUploadCount } = useContext(DashboardContext);

  const [isLogoutConfirmModalOpen, setIsLogoutConfirmModalOpen] = useState(false);
  const { t } = useTranslation();
  const { newModels, setIsOverViewCountModalOpen } = useContext(UserContext);
  /*----------------------------------------
                  Analytics
   ----------------------------------------*/
  const logoutAnalytics = async () => {
    await amplitude.sendAsyncEvent(
      location.pathname.includes('mypage')
        ? amplitudeEvents.gnb.zero_mypage_logout
        : amplitudeEvents.gnb.zero_gnb_logout,
    );
    amplitude.reset();
  };
  /*----------------------------------------
                Business Logic
   ----------------------------------------*/
  const isSelected = (path: string) => {
    return location.pathname.indexOf(path) > 0;
  };
  /*----------------------------------------
                  Event Handler
   ----------------------------------------*/
  const logoutClicked = async () => {
    logoutAnalytics();
    try {
      await revokeToken();
    } catch (e) {
      console.log(e);
    }
    localStorage.removeItem(JWT_ACCESS_TOKEN_LOCALSTORAGE_KEY);
    localStorage.removeItem(JWT_REFRESH_TOKEN_LOCALSTORAGE_KEY);
    navigate('/login');
  };

  const pageNavigate = (url: string) => {
    navigate(`/dashboard/${url}`);
  };

  const openChannelIO = () => {
    if (location.hash.length > 0) {
      navigate(location.pathname);
    }
    amplitude.sendEvent(amplitudeEvents.gnb.zero_gnb_helpcenter);
    (window as any).ChannelIO('openChat');
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      <div className="Dashboard__navigator">
        <ModalMaker isVisible={isLogoutConfirmModalOpen} setIsVisible={setIsLogoutConfirmModalOpen}>
          <div className="LogoutModal">
            <div className="LogoutModal__title">{`${t('DashboardNavigator.로그아웃 하시겠습니까?')}`}</div>
            <div className="LogoutModal__button">
              <RDSButton
                type="noBox"
                color="grey"
                onClick={() => {
                  setIsLogoutConfirmModalOpen(false);
                }}
              >
                {`${t('MyPage.취소')}`}
              </RDSButton>
              <RDSButton type="noBox" color={'warning'} onClick={logoutClicked}>
                {`${t('DashboardNavigator.로그아웃')}`}
              </RDSButton>
            </div>
          </div>
        </ModalMaker>
        <RDSUpload.Input checkValidation={(file) => validationFile(file)}>
          {({ handleOnChange }) => (
            <>
              <input
                ref={inputRef}
                id="RDSUpload-gnb-input"
                type="file"
                multiple
                onChange={async (e) => {
                  setSpinner('loading-spinner');
                  const remainingModelCount = await checkRemainingModelCount();
                  setSpinner('');
                  const fileCount = e.target.files?.length || 0;
                  if (remainingModelCount - fileCount < 0) {
                    setRemainingUploadCount(remainingModelCount);
                    inputRef.current!.value = '';
                    setIsOverUploadModalOpen(true);
                    return;
                  }
                  handleOnChange(e);
                  amplitude.sendEvent(amplitudeEvents.gnb.zero_gnb_fileupload_start);
                  inputRef.current!.value = '';
                }}
                onClick={() => (inputRef.current!.value = '')}
                style={{ display: 'none' }}
              ></input>
            </>
          )}
        </RDSUpload.Input>
        <img
          src={Logo}
          alt="gnblogo"
          style={{ width: '147.85px', height: '37.85px', cursor: 'pointer', marginTop: '10px' }}
          onClick={() => {
            amplitude.sendEvent(amplitudeEvents.gnb.zero_gnb_logo);
            navigate('/main');
          }}
        />
        <RDSIconTextButton
          customStyle={{ marginTop: '10px' }}
          fullWidth
          size="medium"
          icon={<UploadIcon />}
          padding={10}
          onClick={() => {
            amplitude.sendEvent(amplitudeEvents.gnb.zero_gnb_fileupload);
            inputRef.current?.click();
          }}
        >
          {`${t('DashboardNavigator.3D 모델 업로드')}`}
        </RDSIconTextButton>
        <div className="Dashboard__navigator__separator" style={{ background: 'none' }}></div>
        <div className="Dashboard__navigator__header">{`${t('DashboardNavigator.모델 라이브러리')}`}</div>
        <RDSGNBListButton
          icon={<ModelIcon />}
          type="noBox"
          showNewBadge={newModels}
          selected={isSelected('models')}
          onClick={() => {
            amplitude.sendEvent(amplitudeEvents.gnb.zero_gnb_allmodel);
            pageNavigate('models');
          }}
          customStyle={{ width: '100%' }}
        >
          {`${t('DashboardNavigator.전체 모델')}`}
        </RDSGNBListButton>
        <RDSGNBListButton
          icon={<OpenModelIcon />}
          type="noBox"
          selected={isSelected('catalog')}
          onClick={async () => {
            amplitude.sendEvent(amplitudeEvents.gnb.zero_gnb_catalog);
            (await checkOverViewCount()).isOverViewCount && setIsOverViewCountModalOpen(true);
            pageNavigate('catalog');
          }}
          customStyle={{ width: '100%' }}
        >
          {`${t('DashboardNavigator.3D 카탈로그')}`}
        </RDSGNBListButton>
        <RDSGNBListButton
          icon={<AutoGraphIcon />}
          type="noBox"
          selected={isSelected('analysis')}
          onClick={() => {
            amplitude.sendEvent(amplitudeEvents.gnb.zero_gnb_traffic);
            pageNavigate('analysis');
          }}
          customStyle={{ width: '100%' }}
        >
          {`${t('DashboardNavigator.트래픽 확인')}`}
        </RDSGNBListButton>
        <div className="Dashboard__navigator__separator"></div>
        <div className="Dashboard__navigator__header">{`${t('DashboardNavigator.서비스 이용방법')}`}</div>
        <RDSGNBListButton
          icon={<InfoOutlinedIcon />}
          type="noBox"
          selected={isSelected('plicar')}
          onClick={() => {
            amplitude.sendEvent(amplitudeEvents.gnb.zero_gnb_introduce);
            pageNavigate('service/plicar');
          }}
          customStyle={{ width: '100%' }}
        >
          {`${t('DashboardNavigator.PlicAR 소개')}`}
        </RDSGNBListButton>
        <RDSGNBListButton
          icon={<QuestionAnswerIcon />}
          type="noBox"
          onClick={() => openChannelIO()}
          customStyle={{ width: '100%' }}
        >
          {`${t('DashboardNavigator.문의하기')}`}
        </RDSGNBListButton>
        <div className="Dashboard__navigator__separator"></div>
        <div className="Dashboard__navigator__header">{`${t('DashboardNavigator.설정')}`}</div>
        {/* <RDSGNBListButton
          icon={<CreditCardOutlinedIcon />}
          type="noBox"
          selected={isSelected('payment')}
          onClick={() => {
            amplitude.sendEvent(amplitudeEvents.gnb.zero_gnb_payment);
            pageNavigate('payment');
          }}
          customStyle={{ width: '100%' }}
        >
          {`${t('DashboardNavigator.결제하기')}`}
        </RDSGNBListButton> */}
        <RDSGNBListButton
          icon={<PeopleIcon />}
          type="noBox"
          selected={isSelected('mypage')}
          onClick={() => {
            amplitude.sendEvent(amplitudeEvents.gnb.zero_gnb_mypage);
            pageNavigate('mypage');
          }}
          customStyle={{ width: '100%' }}
        >
          {`${t('DashboardNavigator.마이페이지')}`}
        </RDSGNBListButton>
        <RDSGNBListButton
          icon={<LogoutIcon />}
          type="noBox"
          onClick={() => setIsLogoutConfirmModalOpen(true)}
          customStyle={{ width: '100%' }}
        >
          {`${t('DashboardNavigator.로그아웃')}`}
        </RDSGNBListButton>
      </div>
    </>
  );
};

export default DashboardNavigator;
